'use client';

import React, { useEffect, useState, useRef } from 'react';
import { createRoot, Root } from 'react-dom/client';
import Events from "@/components/edubox/Events";
import Testimonials from "@/components/edubox/Testimonials";
import Quotes from "@/components/edubox/Quotes";
import Edubox from "@/components/edubox/Edubox";
import Partners from "@/components/edubox/Partners";
import Service from "@/components/edubox/Service";
import Link from "next/link";
import Footer from "@/components/edubox/Footer.tsx";
import {cn} from "@/helpers/className.ts";
import {Skeleton} from "antd";

export const dynamic = "force-dynamic";


// Function to dynamically import SCSS files
const loadStyles = async () => {
  await import('@/components/edubox/css/index.scss');
};

const EduboxPage: React.FC = () => {
  const [boxes, setBoxes] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [testimonials, setTestimonials] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Refs to store root instances
  const boxesRootRef = useRef<Root | null>(null);
  const partnersRootRef = useRef<Root | null>(null);
  const eventsTeasersRootRef = useRef<Root | null>(null);
  const eventsListRootRef = useRef<Root | null>(null);
  const testimonialsRootRef = useRef<Root | null>(null);
  const quotesRootRef = useRef<Root | null>(null);

  const HeroSection = () => (
    <section
      className="vrt-edubox__section vrt-edubox__section--hero"
      style={{ background: 'url(/nl/edubox/resources/images/hero.jpg) right/cover' }}
    >
      <img src="/nl/edubox/resources/images/bulb-bg2.png" className="vrt-edubox__visual" alt="Hero Visual" />
      <div className="vrt-edubox__hero">
        <h1 className="vrt-edubox__title">Beleef de wereld met een frisse blik</h1>
        <div className="vrt-edubox__subtitle">
          Dompel je klas onder in boeiende thema’s met interactieve leerervaringen op maat van jongeren, verrijkt met
          audiovisueel materiaal en VRT-experten.
        </div>
        <div className="vrt-edubox__perspective">
          <Link href="/edubox/catalogus" className="vrt-edubox__button">
            <span className="vrt-edubox__button-label">Ontdek alle eduboxen</span>
            <span className="vrt-edubox__button-label vrt-edubox__button-icon"></span>
          </Link>
        </div>
      </div>
    </section>
  );

  useEffect(() => {
    // Load styles on component mount
    loadStyles();

    async function fetchData() {
      try {
        const eventsData = await Service.getEvents();
        setEvents(eventsData.data);

        const boxesData = await Service.getBoxes();
        setBoxes(boxesData.data);

        const testimonialsData = await Service.getTestimonials();
        setTestimonials(testimonialsData.data);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (boxes.length > 0) {
      const reactBoxesElement = document.querySelector('.vrt-edubox__react-boxes');
      const reactPartnersElement = document.querySelector('.vrt-edubox__react-partners');

      if (reactBoxesElement) {
        if (!boxesRootRef.current) {
          boxesRootRef.current = createRoot(reactBoxesElement);
        }
        boxesRootRef.current.render(<Edubox boxes={boxes} />);
      }

      if (reactPartnersElement) {
        if (!partnersRootRef.current) {
          partnersRootRef.current = createRoot(reactPartnersElement);
        }
        partnersRootRef.current.render(<Partners boxes={boxes} />);
      } else {
        // Use MutationObserver to wait for the element to be added to the DOM
        const observer = new MutationObserver((mutations) => {
          for (let mutation of mutations) {
            if (mutation.type === 'childList') {
              const newReactPartnersElement = document.querySelector('.vrt-edubox__react-partners');
              if (newReactPartnersElement) {
                observer.disconnect();
                if (!partnersRootRef.current) {
                  partnersRootRef.current = createRoot(newReactPartnersElement);
                }
                partnersRootRef.current.render(<Partners boxes={boxes} />);
              }
            }
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
      }
    }
  }, [boxes]);

  useEffect(() => {
    if (events.length > 0) {
      const eventsTeasersElement = document.querySelector('.vrt-edubox__events-teasers');
      const eventsListElement = document.querySelector('.vrt-edubox__events-list');

      if (eventsTeasersElement) {
        if (!eventsTeasersRootRef.current) {
          eventsTeasersRootRef.current = createRoot(eventsTeasersElement);
        }
        eventsTeasersRootRef.current.render(<Events events={events} highlight={true} />);
      } else {
        // Use MutationObserver to wait for the element to be added to the DOM
        const observer = new MutationObserver((mutations) => {
          for (let mutation of mutations) {
            if (mutation.type === 'childList') {
              const newReactEventsElement = document.querySelector('.vrt-edubox__events-teasers');
              if (newReactEventsElement) {
                observer.disconnect();
                if (!eventsTeasersRootRef.current) {
                  eventsTeasersRootRef.current = createRoot(newReactEventsElement);
                }
                eventsTeasersRootRef.current.render(<Events events={events} highlight={true} />);
              }
            }
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
      }

      if (eventsListElement) {
        if (!eventsListRootRef.current) {
          eventsListRootRef.current = createRoot(eventsListElement);
        }
        eventsListRootRef.current.render(<Events events={events} />);
      }
    }
  }, [events]);

  useEffect(() => {
    if (testimonials.length > 0) {
      const testimonialsElement = document.querySelector('.vrt-edubox__testimonials');
      const quotesElement = document.querySelector('.vrt-edubox__react-quotes');

      if (testimonialsElement) {
        if (!testimonialsRootRef.current) {
          testimonialsRootRef.current = createRoot(testimonialsElement);
        }
        testimonialsRootRef.current.render(<Testimonials testimonials={testimonials} />);
      }

      if (quotesElement) {
        if (!quotesRootRef.current) {
          quotesRootRef.current = createRoot(quotesElement);
        }
        quotesRootRef.current.render(<Quotes testimonials={testimonials} highlight={true} />);
      }
    }
  }, [testimonials]);

  if (loading) {
    return (
      <div className={"container mx-auto"}>
        <div className={cn('faq-list-items flex flex-col gap-4 my-20')}>
          {Array.from({ length: 8 }).map((_, index) => (
            <div key={index} className="flex flex-col gap-4">
              <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <main className="vrt-edubox-wrapper grid-container">
      <div className="cell grid-x large-auto align-justify align-middle">
        <a href="/nl/edubox/" className="vrt-edubox__link">
          <img className="vrt-edubox__logo"
               src="/nl/edubox/resources/images/logo.png" alt="EDUbox Logo"/>
        </a>

        <nav className="vrt-edubox__menu">
          <ol className="vrt-edubox__menu-list">
            <li className="vrt-edubox__menu-item">
              <Link href="/edubox/" data-page="edubox" className="vrt-edubox__link js-active">Startpagina</Link>
            </li>
            <li className="vrt-edubox__menu-item">
              <Link href="/edubox/catalogus/" data-page="catalogus" className="vrt-edubox__link">Catalogus</Link>
            </li>
            <li className="vrt-edubox__menu-item">
              <Link href="/edubox/verklaringen/" data-page="verklaringen" className="vrt-edubox__link">Ambassadeurs</Link>
            </li>
            <li className="vrt-edubox__menu-item">
              <Link href="/edubox/evenementen/" data-page="evenementen" className="vrt-edubox__link">Evenementen</Link>
            </li>
            <li className="vrt-edubox__menu-item">
              <Link href="/edubox/leerplatform/" data-page="leerplatform" className="vrt-edubox__link">Voor
                leerplatformen</Link>
            </li>
          </ol>
        </nav>
      </div>

      <div className="cell grid-x large-auto grid--edge">
        <HeroSection/>

        <div className="grid-x align-middle grid-offset-top-1">
          <div className="cell large-4 large-offset-1">
            <h2 className="vrt-edubox__title">EDUbox is ...</h2>
            <div className="vrt-edubox__description">
              <p>
                ... een educatief concept van VRT NWS om jongeren uit het secundair onderwijs te laten kennismaken met
                een
                bepaald maatschappelijk thema. EDUbox wil jongeren informeren en hen stimuleren om zelf aan de slag te
                gaan.
              </p>
              <p>
                EDUbox is gemaakt voor gebruik in de klas, waar leerlingen het onderwerp zelfstandig in handen kunnen
                nemen. De
                leerlingen werken in groepjes of individueel met een box gevuld met fiches, die zowel fysiek als
                digitaal
                en in
                interactieve vorm beschikbaar zijn. Deze fiches bevatten stukjes theorie, praktijkoefeningen en
                opdrachten
                in
                combinatie met een uitgebreid digitaal luik met audiovisueel materiaal.
              </p>
              <p>
                Met dit gratis leermiddel werken leerkrachten aan verschillende eindtermen van de basisvorming van elke
                graad en elke finaliteit.
              </p>
            </div>
          </div>
          <div className="cell large-5 large-offset-1 text-center">
            <iframe
              width="500"
              height="300"
              className="vrt-edubox__iframe"
              src="https://nws.vrt.be/EDUbox-trailer"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <br/>
            <div className="vrt-edubox__perspective">
              <a className="vrt-edubox__button" href="https://nws.vrt.be/EDUbox-leerkrachten" target="_blank">
                <span className="vrt-edubox__button-label">Ontdek de mini-EDUbox voor leerkrachten</span>
                <span className="vrt-edubox__button-label vrt-edubox__button-icon"></span>
              </a>
            </div>
          </div>
        </div>

        <div className="grid-x align-middle grid-offset-top-1">
          <div className="cell large-6 large-offset-1 vrt-edubox__full-box">
            <h2 className="vrt-edubox__title">Ontdek EDUbox op alle evenementen waar we aanwezig zijn ...</h2>
            <div className="vrt-edubox__perspective">
              <Link href="/edubox/evenementen" className="vrt-edubox__button">
                <span className="vrt-edubox__button-label">Ontdek de evenementen</span>
                <span className="vrt-edubox__button-label vrt-edubox__button-icon"></span>
              </Link>
            </div>
          </div>
          <div className="cell large-4 text-center vrt-edubox__events--scroll swiper-container swiper-events">
            <div className="vrt-edubox__events-teasers vrt-edubox__events swiper-wrapper"></div>
            <div className="swiper-pagination"></div>
          </div>
        </div>

        <div className="vrt-edubox__splash grid-offset-top-1"
             style={{background: 'url(/nl/edubox/resources/images/seamless_bg2.jpg) center top'}}>
          <div className="grid-x grid-offset-top-1">
            <div className="cell large-6 large-offset-1 text-center large-text-left margin-bottom-2">
              <img
                src="/nl/edubox/resources/images/BlauweLijntjes/Experten.png"
                className="vrt-edubox__drawing" alt=""/>
            </div>
            <div className="cell large-5 text-center large-text-left">
              <h3 className="vrt-edubox__title">Experten</h3>
              <div className="vrt-edubox__description">
                <p>
                  In elke EDUbox nemen experten van VRT NWS en de inhoudelijke partners (zoals <b>Mediawijs, imec, Brightlab,
                  Childfocus, FARO, BELvue Museum</b> enz.) de leerlingen mee op sleeptouw. Zo leggen bijvoorbeeld <b>Jacotte
                  Brokken, Lonne Van Erp, Riadh Bahri</b> en veel andere experten moeilijke concepten op eenvoudige wijze uit.
                </p>
              </div>
            </div>
          </div>

          <div className="grid-x grid-offset-top-3">
            <div className="cell large-7 large-order-2 text-center large-text-right margin-bottom-2">
              <img
                src="/nl/edubox/resources/images/BlauweLijntjes/Audiovisueel.png"
                className="vrt-edubox__drawing" alt=""/>
            </div>
            <div className="cell large-4 large-order-1 large-offset-1 text-center large-text-left">
              <h3 className="vrt-edubox__title">Audiovisueel</h3>
              <div className="vrt-edubox__description">
                <p>
                  VRT NWS draait voor elke EDUbox op maat gemaakte reportages die complementair zijn aan de inhoud van
                  de
                  box.
                  Dit audiovisueel materiaal verrijkt de ervaring voor leerlingen en verruimt hun blik.
                </p>
              </div>
            </div>
          </div>

          <div className="grid-x grid-offset-top-4">
            <div className="cell large-7 text-center large-text-left margin-bottom-2">
              <img
                src="/nl/edubox/resources/images/BlauweLijntjes/OpMaatvdDleerkracht.png"
                className="vrt-edubox__drawing" alt=""/>
            </div>
            <div className="cell large-5 text-center large-text-left">
              <h3 className="vrt-edubox__title">Op maat van elke leerkracht</h3>
              <div className="vrt-edubox__description">
                <p>
                  EDUbox speelt in op meerdere lesvormen, zodat elke leerkracht zelf kan kiezen hoe hij of zij de EDUbox
                  inzet.
                  De originele EDUbox is ontworpen om in groep te gebruiken, maar we bieden ook formats aan die je
                  toelaten de
                  EDUbox individueel in te zetten. EDUbox is dus ook handig materiaal voor afstandsonderwijs of flip
                  teaching.
                </p>
              </div>
            </div>
          </div>

          <div className="grid-x grid-offset-top-2">
            <div className="cell large-6 large-order-2 large-offset-1 text-center margin-bottom-2">
              <img
                src="/nl/edubox/resources/images/BlauweLijntjes/Eindtermen.png"
                className="vrt-edubox__drawing" alt=""/>
            </div>
            <div className="cell large-4 large-order-1 large-offset-1 text-center large-text-left">
              <h3 className="vrt-edubox__title">Eindtermen</h3>
              <div className="vrt-edubox__description">
                <p>
                  Elke EDUbox helpt je bij het realiseren van eindtermen uit één of meerdere sleutelcompetenties.
                  Hiervoor
                  werken we voor elke EDUbox samen met gespecialiseerde partners die expertise hebben opgebouwd in het
                  onderwijs.
                </p>
              </div>
            </div>
          </div>

          <div className="grid-x grid-offset-top-4">
            <div className="cell large-6 large-offset-1 text-center large-text-left margin-bottom-2">
              <img
                src="/nl/edubox/resources/images/BlauweLijntjes/Interactief.png"
                className="vrt-edubox__drawing" alt=""/>
            </div>
            <div className="cell large-4 large-offset-1 text-center large-text-left">
              <h3 className="vrt-edubox__title">(Inter)actief</h3>
              <div className="vrt-edubox__description">
                <p>
                  Elke EDUbox houdt al van bij het ontwerp rekening met de klaspraktijk en zet daarom in op diverse
                  leerstijlen.
                  Een EDUbox combineert wervende illustraties, tekst, video’s en applicaties met klassikale reflectie en
                  groepswerk.
                </p>
              </div>
            </div>
          </div>

          <div className="grid-x grid-offset-top-3">
            <div className="cell large-5 large-order-2 large-offset-2 text-center large-text-left margin-bottom-2">
              <img src="/nl/edubox/resources/images/BlauweLijntjes/Gratis.png"
                   className="vrt-edubox__drawing" alt=""/>
            </div>
            <div className="cell large-4 large-order-1 large-offset-1 text-center large-text-left">
              <h3 className="vrt-edubox__title">Gratis</h3>
              <div className="vrt-edubox__description">
                <p>
                  EDUbox is gratis lesmateriaal. Van elke EDUbox bestaan verschillende digitale versies die steeds voor
                  iedereen gratis voorhanden zijn in de catalogus.
                </p>
                <div className="vrt-edubox__perspective">
                  <Link href="/edubox/catalogus" className="vrt-edubox__button">
                    <span className="vrt-edubox__button-label">Ontdek alle eduboxen</span>
                    <span className="vrt-edubox__button-label vrt-edubox__button-icon"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid-x grid-offset-top-1">
          <div className="cell large-auto text-center">
            <h2 className="vrt-edubox__title">Ook te vinden op leerplatformen</h2>
          </div>
        </div>

        <br/>

        <div className="grid-x align-bottom">
          <div className="cell large-3 text-center margin-bottom-2">
            <a className="vrt-edubox__link" href="https://ftrprf.be/partnerschappen/" target="_blank">
              <img className="vrt-edubox__image"
                   src="/nl/edubox/resources/images/partners/futurproof.png"
                   alt=""/><br/>
              <div className="vrt-edubox__label">ftrprf</div>
            </a>
          </div>
          <div className="cell large-3 text-center margin-bottom-2">
            <a className="vrt-edubox__link" href="https://www.i-learn.vlaanderen/" target="_blank">
              <img className="vrt-edubox__image"
                   src="/nl/edubox/resources/images/partners/ilearn.png"
                   alt=""/><br/>
              <div className="vrt-edubox__label">i-learn</div>
            </a>
          </div>
          <div className="cell large-3 text-center margin-bottom-2">
            <a className="vrt-edubox__link" href="https://www.lessonup.com/nl/channel/vrt" target="_blank">
              <img className="vrt-edubox__image"
                   src="/nl/edubox/resources/images/partners/lessonup.png"
                   alt=""/><br/>
              <div className="vrt-edubox__label">LessonUp</div>
            </a>
          </div>
          <div className="cell large-3 text-center margin-bottom-2">
            <a className="vrt-edubox__link" href="https://www.smartschool.be/vrt" target="_blank">
              <img src="/nl/edubox/resources/images/partners/smartschool.png"
                   alt=""/><br/>
              <div className="vrt-edubox__label">Smartschool</div>
            </a>
          </div>
          <div className="cell large-12 text-center margin-bottom-2">
          <span
            className="vrt-edubox__title--small">Wil je als leerplatform aan de slag met EDUbox? Dat kan!</span><br/>
            <div className="vrt-edubox__perspective">
              <Link href="/edubox/leerplatform" className="vrt-edubox__button">
                <span className="vrt-edubox__button-label">Werk met ons samen</span>
                <span className="vrt-edubox__button-label vrt-edubox__button-icon"></span>
              </Link>
            </div>
          </div>
        </div>

        <div className="grid-x grid-offset-top-1">
          <div className="cell large-12 text-center swiper-container swiper-quotes">
            <div className="vrt-edubox__react-quotes swiper-wrapper" data-data="home"></div>
            <div className="swiper-pagination"></div>
          </div>
        </div>

        <div className="vrt-edubox__react-partners"></div>

        <Footer />
      </div>
    </main>
  );
};

export default EduboxPage;